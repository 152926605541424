// app/javascript/javascripts/message_dropdown_functionality.js

export function setupMessageDropdownFunctionality() {
    console.log('Setting up message dropdown functionality');
  
    // Handle mark all as read button clicks
    document.addEventListener('click', function(e) {
      // Update this line to check for message-specific button or container
      const button = e.target.closest('.messages-area .js-mark-messages-read');
      if (button) {
        console.log('Mark all messages as read button clicked');
        
        // Add loading state
        const originalText = button.textContent;
        button.textContent = 'Marking as read...';
        button.disabled = true;
        
        const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
        
        fetch(button.dataset.url, {
          method: 'POST',
          headers: {
            'X-CSRF-Token': csrfToken,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          credentials: 'same-origin'
        })
        .then(response => {
          console.log('Response status:', response.status);
          return response.json();
        })
        .then(data => {
          console.log('Response data:', data);
          if (data.success) {
            console.log('Updating UI...');
            
            // Hide message count badge
            const badge = document.querySelector('.messages-btn .abs-area');
            if (badge) {
              console.log('Found badge, hiding it');
              badge.style.display = 'none';
            }
  
            // Update messages content
            const messagesList = document.querySelector('.messages-list');
            if (messagesList) {
              console.log('Found messages list, clearing it');
              messagesList.innerHTML = `
                <div class="text-center mb-7">
                  <p class="mdtxt text-muted">No new messages</p>
                </div>
              `;
            }
  
            // Remove the mark all as read button
            const headerContainer = document.querySelector('.messages-content .d-flex.justify-content-between');
            if (headerContainer) {
              // Keep only the h5 element
              const title = headerContainer.querySelector('h5');
              headerContainer.innerHTML = '';
              headerContainer.appendChild(title);
            }
          }
        })
        .catch(error => {
          console.error('Error:', error);
          // Reset button state on error
          button.textContent = originalText;
          button.disabled = false;
        });
      }
    });
}