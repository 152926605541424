// app/javascript/javascripts/stories.js

export const setupStoriesFunctionality = () => {
  console.log('Setting up stories functionality...');
  
  const carousel = document.querySelector('.story-carousel');
  if (!carousel) {
    console.log('No story carousel found');
    return;
  }
  
  if (carousel.classList.contains('slick-initialized')) {
    console.log('Destroying existing Slick carousel');
    $(carousel).slick('unslick');
  }

  console.log('Initializing Slick carousel');
  $(carousel).slick({
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 5,
    slideWidth: 159,  // Set explicit slide width
    variableWidth: true,
    swipeToSlide: true,
    prevArrow: '<button type="button" class="arafat-prev cmn-btn"><i class="material-symbols-outlined mat-icon">chevron_left</i></button>',
    nextArrow: '<button type="button" class="arafat-next cmn-btn"><i class="material-symbols-outlined mat-icon">chevron_left</i></button>',
    // Add event handler for slide change
    onBeforeChange: function(slick, currentSlide, nextSlide) {
        const slideWidth = 159; // Width of each slide
        const translateX = nextSlide * slideWidth * -1;
        $('.slick-track').css('transform', `translate3d(${translateX}px, 0px, 0px)`);
    },
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4  // Match slidesToShow
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3  // Match slidesToShow
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,  // Match slidesToShow
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,  // Match slidesToShow
          arrows: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,  // Match slidesToShow
          arrows: false,
          centerMode: false,
          centerPadding: '40px',
        }
      }
    ]
  });
  
  // Initialize video functionality after carousel is ready
  if (typeof initVideoModal === 'function') {
    console.log('Reinitializing video modal after carousel setup');
    initVideoModal();
  }
};

// Initialize on page load events
document.addEventListener('DOMContentLoaded', setupStoriesFunctionality);
document.addEventListener('turbolinks:load', setupStoriesFunctionality);