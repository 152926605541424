import consumer from "../channels/consumer"

export function setupNotificationFunctionality() {
  console.log('Setting up notification functionality');

  // Handle mark all as read button clicks
  document.addEventListener('click', function(e) {
    const button = e.target.closest('.js-mark-notifications-read');
    if (button) {
      console.log('Mark all as read button clicked');
      
      // Add loading state
      button.textContent = 'Marking as read...';
      button.disabled = true;
      
      const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
      
      fetch(button.dataset.url, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': csrfToken,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        credentials: 'same-origin'
      })
      .then(response => {
        console.log('Response status:', response.status);
        return response.json();
      })
      .then(data => {
        console.log('Response data:', data);
        if (data.success) {
          console.log('Updating UI...');
          
          // Hide notification count badge
          const badge = document.querySelector('.notification-btn .abs-area');
          if (badge) {
            console.log('Found badge, hiding it');
            badge.style.display = 'none';
          }

          // Update notification content
          const notificationsContainer = document.querySelector('.notifications-list');
          if (notificationsContainer) {
            console.log('Found notifications container, clearing it');
            notificationsContainer.innerHTML = `
              <div class="text-center mb-7">
                <p class="mdtxt text-muted">No new notifications</p>
              </div>
            `;
          }

          // Find and remove the button entirely
          button.remove();

          // Make sure the header only contains the title
          const headerContainer = document.querySelector('.notification-content .d-flex.justify-content-between');
          if (headerContainer) {
            // Keep only the h5 element
            const title = headerContainer.querySelector('h5');
            headerContainer.innerHTML = '';
            headerContainer.appendChild(title);
          }
        }
      })
      .catch(error => {
        console.error('Error:', error);
        // Reset button state on error
        button.textContent = 'Mark all as read';
        button.disabled = false;
      });
    }
  });

  // Set up notification channel subscription
  setupNotificationChannel();
}

function setupNotificationChannel() {
  consumer.subscriptions.create("NotificationChannel", {
    connected() {
      console.log("Connected to notifications channel");
    },

    disconnected() {
      console.log("Disconnected from notifications channel");
    },

    received(data) {
      console.log("Received notification data:", data);
      
      // Update notification counter
      const counter = document.querySelector('.notification-btn .abs-area');
      if (counter) {
        if (data.unread_count > 0) {
          counter.textContent = data.unread_count;
          counter.style.display = '';
          
          // Show mark all as read button if not present
          const header = document.querySelector('.notification-content .d-flex.justify-content-between');
          if (header && !header.querySelector('.js-mark-notifications-read')) {
            header.innerHTML = `
              <h5 class="m-0">Notifications</h5>
              <button type="button" 
                      class="text-primary text-sm js-mark-all-read"
                      style="background: none; border: none; cursor: pointer;"
                      data-url="/notifications/mark_all_as_read">
                Mark all as read
              </button>
            `;
          }
        } else {
          counter.style.display = 'none';
          
          // Make sure the mark all as read button is removed
          const markAllButton = document.querySelector('.js-mark-all-read');
          if (markAllButton) {
            markAllButton.remove();
          }
        }
      }

      // Add new notification to the list if provided
      if (data.notification_html) {
        const notificationsContainer = document.querySelector('.notifications-list');
        if (notificationsContainer) {
          // If there's a "no notifications" message, remove it
          const emptyMessage = notificationsContainer.querySelector('.text-center.mb-7');
          if (emptyMessage) {
            emptyMessage.remove();
          }

          // Add the new notification at the top
          notificationsContainer.insertAdjacentHTML('afterbegin', data.notification_html);
        }
      }
    }
  });
}