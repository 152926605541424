// app/javascript/javascripts/video_modal.js
import $ from 'jquery'
window.jQuery = window.$ = $

export const setupVideoModal = () => {
    console.log('Setting up video modal - new version');
    
    const modal = document.getElementById('videoModal');
    const videoContainer = document.getElementById('videoContainer');
    const closeBtn = document.querySelector('.video-modal-close');
  
    if (!modal || !videoContainer || !closeBtn) {
      console.warn('Modal elements not found:', { modal: !!modal, container: !!videoContainer, closeBtn: !!closeBtn });
      return;
    }
  
    // First remove any existing listeners to prevent duplicates
    document.querySelectorAll('.video-modal-trigger').forEach(trigger => {
      trigger.removeEventListener('click', handleVideoClick);
    });
  
    // Then add fresh listeners
    document.querySelectorAll('.video-modal-trigger').forEach(trigger => {
      console.log('Adding click listener to trigger:', trigger);
      trigger.addEventListener('click', handleVideoClick);
    });
  
    function handleVideoClick(e) {
      e.preventDefault();
      e.stopPropagation();
      console.log('Video trigger clicked');
      
      // Note: dataset automatically converts data-video-type to videoType
      const videoType = this.getAttribute('data-video-type');
      const videoUrl = this.getAttribute('data-video-url');
      
      console.log('Video type:', videoType);
      console.log('Video URL:', videoUrl);
      
      if (!videoUrl) {
        console.warn('No video URL found');
        return;
      }
  
      let videoContent = '';
      
      if (videoType === 'youtube') {
        // Remove autoplay=1 from the URL if it exists
        const cleanUrl = videoUrl.split('?')[0];
        const videoId = getYouTubeId(cleanUrl);
        
        if (!videoId) {
          console.warn('Could not extract YouTube ID from:', cleanUrl);
          return;
        }
  
        console.log('Loading YouTube video:', videoId);
        videoContent = `
          <iframe 
            width="860" 
            height="515" 
            src="https://www.youtube.com/embed/${videoId}?autoplay=1" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen>
          </iframe>
        `;
      } else {
        videoContent = `
          <video width="860" height="515" controls autoplay>
            <source src="${videoUrl}" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        `;
      }
      
      videoContainer.innerHTML = videoContent;
      modal.style.display = 'block';
      document.body.style.overflow = 'hidden';
    }
  
    function closeVideoModal() {
      modal.style.display = 'none';
      videoContainer.innerHTML = '';
      document.body.style.overflow = '';
    }
  
    // Close button handler
    closeBtn.addEventListener('click', (e) => {
      e.preventDefault();
      closeVideoModal();
    });
  
    // Click outside modal handler
    modal.addEventListener('click', (e) => {
      if (e.target === modal) {
        closeVideoModal();
      }
    });
  
    // ESC key handler
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape' && modal.style.display === 'block') {
        closeVideoModal();
      }
    });
  };
  
  function getYouTubeId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  }
  
  // Initialize for both regular page load and Turbolinks navigation
  document.addEventListener('DOMContentLoaded', () => {
    console.log('DOMContentLoaded - initializing video modal');
    setupVideoModal();
  });
  
  document.addEventListener('turbolinks:load', () => {
    console.log('turbolinks:load - initializing video modal');
    setupVideoModal();
  });
  
  // Handle Slick carousel updates
  $(document).on('afterChange', '.story-carousel', () => {
    console.log('Slick afterChange - reinitializing video modal');
    setupVideoModal();
  });