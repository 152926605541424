import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

const DistanceFilter = () => {
  const [distance, setDistance] = useState(6000);
  const [userHasLocation, setUserHasLocation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    checkUserAddress();
  }, []);

  const checkUserAddress = async () => {
    try {
      const response = await fetch('/api/user/has_address', {
        headers: {
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        }
      });
      
      if (!response.ok) throw new Error('Failed to check address');
      
      const data = await response.json();
      setUserHasLocation(data.hasAddress);
      
      if (!data.hasAddress) {
        console.log('User location not available:', data.message);
      }
    } catch (error) {
      console.error('Error checking user address:', error);
      setUserHasLocation(false);
    }
  };

  const reinitializeFunctionalities = () => {
    console.log('Starting reinitialization...');
    console.log('Available functions:', {
      setupLikeFunctionality: typeof window.setupLikeFunctionality,
      setupCommentFunctionality: typeof window.setupCommentFunctionality,
      setupNotificationFunctionality: typeof window.setupNotificationFunctionality,
      setupMessageDropdownFunctionality: typeof window.setupMessageDropdownFunctionality,
      setupStoriesFunctionality: typeof window.setupStoriesFunctionality,
      setupShareFunctionality: typeof window.setupShareFunctionality
    });
  
    const functionsToReinitialize = [
      { name: 'setupLikeFunctionality', func: window.setupLikeFunctionality },
      { name: 'setupCommentFunctionality', func: window.setupCommentFunctionality },
      { name: 'setupNotificationFunctionality', func: window.setupNotificationFunctionality },
      { name: 'setupMessageDropdownFunctionality', func: window.setupMessageDropdownFunctionality },
      { name: 'setupStoriesFunctionality', func: window.setupStoriesFunctionality },
      { name: 'setupShareFunctionality', func: window.setupShareFunctionality }
    ];
  
    functionsToReinitialize.forEach(({ name, func }) => {
      if (typeof func === 'function') {
        try {
          console.log(`Reinitializing ${name}...`);
          func();
          console.log(`Successfully reinitialized ${name}`);
        } catch (error) {
          console.error(`Error reinitializing ${name}:`, error);
        }
      } else {
        console.warn(`Function ${name} not found or not a function`);
      }
    });
  };

  const updateListings = async (newDistance) => {
    setIsLoading(true);
    const spinner = document.getElementById('infinite-scroll-spinner');
    if (spinner) spinner.style.display = 'block';

    try {
      // Update the distance filter
      const filterResponse = await fetch('/api/listings/filter_distance', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
          'Accept': 'application/json'
        },
        body: JSON.stringify({ distance: newDistance })
      });

      if (!filterResponse.ok) {
        throw new Error('Failed to update distance filter');
      }

      // Fetch updated listings
      const listingsResponse = await fetch('/feed?filter_distance=true', {
        headers: {
          'Accept': 'text/javascript',
          'X-Requested-With': 'XMLHttpRequest'
        }
      });

      if (!listingsResponse.ok) {
        throw new Error('Failed to fetch listings');
      }

      const text = await listingsResponse.text();
      console.log('Received listings response');

      // Execute the response
      const script = document.createElement('script');
      script.text = text;
      document.body.appendChild(script);
      document.body.removeChild(script);

      // Call reinitializeFunctionalities after a short delay
      setTimeout(() => {
        console.log('Calling reinitializeFunctionalities...');
        reinitializeFunctionalities();
      }, 250);

    } catch (error) {
      console.error('Error updating listings:', error);
      const container = document.getElementById('listings-container');
      if (container) {
        container.innerHTML = `
          <div class="alert alert-danger">
            <p>Error loading listings. Please try again.</p>
            <small>If the problem persists, try refreshing the page.</small>
          </div>
        `;
      }
    } finally {
      setIsLoading(false);
      if (spinner) spinner.style.display = 'none';
    }
  };

  const handleSliderChange = (e) => {
    setDistance(parseInt(e.target.value));
  };

  const handleSliderRelease = (e) => {
    const newDistance = parseInt(e.target.value);
    updateListings(newDistance);
  };

  if (!userHasLocation) {
    return (
      <div className="distance-filter">
        <div className="mb-4">
          <h6 className="d-inline-flex">Distance Filter</h6>
        </div>
        <p className="text-sm text-gray-500">
          Please add your address in your profile settings to use the distance filter.
        </p>
      </div>
    );
  }

  return (
    <div className="distance-filter">
      <div className="mb-4">
        <h6 className="d-inline-flex">
          Distance Filter&nbsp;
          {isLoading && <span className="ml-2">Loading...</span>}
        </h6>
      </div>
      <div className="flex flex-col gap-2">
        <input
          type="range"
          min="1"
          max="6000"
          value={distance}
          onChange={handleSliderChange}
          onMouseUp={handleSliderRelease}
          onTouchEnd={handleSliderRelease}
          className="w-full"
          disabled={isLoading}
        />
        <div className="flex justify-between">
          <span className="mdtxt">{distance} miles</span>
        </div>
      </div>
    </div>
  );
};

// Make setup function available globally
const setupDistanceFilter = () => {
  console.log('Setting up distance filter');
  const container = document.getElementById('distance-filter');
  if (container) {
    ReactDOM.render(<DistanceFilter />, container);
    console.log('Distance filter mounted');
  } else {
    console.warn('Distance filter container not found');
  }
};

window.setupDistanceFilter = setupDistanceFilter;

export { setupDistanceFilter };
export default DistanceFilter;