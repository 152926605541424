document.addEventListener('turbolinks:load', function() {
    $(document).on('click', '.follow-btn', function(e) {
        e.preventDefault();
        var $button = $(this);
        var $form = $button.closest('form');
        var userId = $button.data('user-id');

        console.log('Button clicked:', $button.text());
        console.log('Form action:', $form.attr('action'));

        $.ajax({
            url: $form.attr('action'),
            method: $form.attr('method'),
            dataType: 'json',
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            },
            success: function(response) {
                console.log('AJAX Success:', response);
                if (response.success) {
                    var $container = $('#follow-button-container-' + userId);
                    if ($button.text() === 'Follow') {
                        $container.html('<form action="' + response.unfollow_path + '" method="post" data-remote="true"><input type="hidden" name="_method" value="delete"><button type="submit" class="cmn-btn fourth follow-btn" data-user-id="' + userId + '">Unfollow</button></form>');
                    } else {
                        $container.html('<form action="' + response.follow_path + '" method="post" data-remote="true"><button type="submit" class="cmn-btn fourth follow-btn" data-user-id="' + userId + '">Follow</button></form>');
                    }
                    
                    // Update followers count if it exists on the page
                    var $followersCount = $('.followers-count-' + userId);
                    if ($followersCount.length) {
                        $followersCount.text(response.followers_count);
                    }
                } else {
                    console.error('Error in response:', response.message);
                    alert(response.message || 'An error occurred. Please try again.');
                }
            },
            error: function(xhr, status, error) {
                console.error('AJAX Error:', status, error);
                console.error('Response Text:', xhr.responseText);
                alert('An error occurred. Please try again.');
            }
        });
    });
});