// app/javascript/javascripts/like_functionality.js

export function setupLikeFunctionality() {
    const likeForms = document.querySelectorAll('.like-form');
    console.log('Setting up like functionality for', likeForms.length, 'forms');
  
    if (likeForms.length === 0) {
      console.log('No like forms found, user might not be logged in');
      return;
    }
  
    likeForms.forEach(form => {
      console.log('Adding event listener to form:', form);
      let debounceTimer;
  
      form.addEventListener('submit', function(e) {
        e.preventDefault();
        
        clearTimeout(debounceTimer);
        
        debounceTimer = setTimeout(() => {
          if (this.dataset.submitting === 'true') {
            console.log('Form is already submitting, ignoring click');
            return;
          }
          
          this.dataset.submitting = 'true';
          
          const listingId = this.dataset.listingId;
          const likeButton = this.querySelector('.like-button');
          const likeText = likeButton.querySelector('.like-text');
          const likesCount = likeButton.querySelector('.likes-count');
          const url = this.action;
  
          console.log('Form submitted for listing', listingId);
          console.log('URL:', url);
  
          fetch(url, {
            method: 'POST',
            headers: {
              'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            },
            body: JSON.stringify({ listing_id: listingId }),
            credentials: 'same-origin'
          })
          .then(response => {
            console.log('Response status:', response.status);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            console.log('Received response:', data);
            if (data.status === 'success') {
              console.log('Updating UI: liked =', data.liked, 'count =', data.likes_count);
              likeButton.classList.toggle('liked', data.liked);
              likeText.textContent = data.liked ? 'Unlike' : 'Like';
              likesCount.textContent = `(${data.likes_count})`;
              console.log('UI updated');
            } else {
              console.error('Error:', data.message);
              alert('An error occurred. Please try again.');
            }
          })
          .catch(error => {
            console.error('Error:', error);
            alert('An error occurred. Please try again.');
          })
          .finally(() => {
            this.dataset.submitting = 'false';
          });
        }, 300);
      });
    });
  }